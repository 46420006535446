import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchOrderList, fetchOrderDeductLog, fetchOrderCostList, fetchPropertyList, updateEndOrder, updateReturn, fetchEquList } from "@/api/charge";
import { fetchMemberList } from "@/api/member";
import "@/utils/global.js";
import { transPayState, downloadFun, orderStopCode, transChargeType } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
import ChargeDataChart from "@/views/charge/order/components/ChargeDataChart";
import OccupyList from "./components/OccupyList.vue";
export default {
  name: "orderList",
  components: {
    Pagination: Pagination,
    ChargeDataChart: ChargeDataChart,
    OccupyList: OccupyList
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        filter: {
          status: 3,
          filter_field: "created_at",
          property_id: "",
          member_id: "",
          charging_id: "",
          charging_type: ""
        },
        keyword: "",
        time: []
      },
      lineStatus: _global.lineStatus,
      orderState: _global.orderState,
      chargeType: _global.chargeType,
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      selectCount: 0,
      selectList: [],
      memberDialog: false,
      dialogForm: {
        money: "",
        remark: "",
        order_id: ""
      },
      dataDialog: false,
      totalMoney: 0,
      totalRefund: 0,
      total: 0,
      showDeductLog: false,
      deductLogTitle: "扣费记录",
      logList: [],
      tableLogLoading: false,
      showCostList: false,
      costListTitle: "费用明细",
      costList: [],
      tableCostListLoading: false,
      //搜索小区
      propertyLoading: false,
      propertyOptions: [],
      orderFilter: [{
        label: "开始时间",
        value: "started_at"
      }, {
        label: "结束时间",
        value: "finished_at"
      }, {
        label: "创建时间",
        value: "created_at"
      }],
      //搜索用户
      memberLoading: false,
      memberOptions: [],
      //搜索充电桩
      chargingLoading: false,
      chargingOptions: [],
      pickerOptions: _global.timeOption,
      activeName: "first"
    };
  },
  created: function created() {
    //this.searchForm.filter.status = this.$route.params && this.$route.params.status;
    this.searchForm.filter.charging_id = this.$route.query ? this.$route.query.charging_id : "";
    this.searchForm.filter.member_id = this.$route.query ? this.$route.query.member_id : "";
    this.searchForm.keyword = this.$route.query ? this.$route.query.keyword : "";
    var end = new Date();
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    this.searchForm.time = [start, end];
    this.handleTime(this.searchForm.time);
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchOrderList(data).then(function (response) {
        console.log(response);
        _this.totalMoney = response.meta.total_money;
        _this.totalRefund = response.meta.total_refund;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.total = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleDownload: function handleDownload() {
      console.log("导出");
      this.btnLoading = true;
      var data = this.searchForm;
      console.log(data, this.searchForm, "aaaa");
      var url = process.env.VUE_APP_URL + "/orders/download?token=".concat(getToken(), "&keyword=").concat(data.keyword || "", "&start_time=").concat(data.start_time, "&end_time=").concat(data.end_time, "&filter[status]=").concat(data.filter.status || "", "&filter[filter_field]=").concat(data.filter.filter_field || "", "&filter[property_id]=").concat(data.filter.property_id || "", "&filter[member_id]=").concat(data.filter.member_id || "", "&filter[charging_id]=").concat(data.filter.charging_id || "");
      console.log(data.filter.tatus, "....");
      console.log(url);
      downloadFun(url, "订单列表.csv");
      this.btnLoading = false;
    },
    handleReturnBtn: function handleReturnBtn() {
      var _this2 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = _this2.dialogForm;
          updateReturn(data).then(function (res) {
            console.log(res);
            _this2.dataDialog = false;

            var _iterator = _createForOfIteratorHelper(_this2.dataList),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.ordersn === data.order_id) {
                  v.status = 5;
                  v.refund = data.money;
                  v.remark = data.remark;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this2.$message({
              type: "success",
              message: "退款成功"
            });
          }).catch(function (err) {
            _this2.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleLog: function handleLog(row) {
      var _this3 = this;

      this.deductLogTitle = "订单:" + row.ordersn + " 扣费记录";
      this.showDeductLog = true;
      this.tableLogLoading = true;
      fetchOrderDeductLog({
        order_id: row.id
      }).then(function (res) {
        console.log(res);
        _this3.logList = res.data;
        _this3.tableLogLoading = false;
      }).catch(function (err) {
        _this3.tableLogLoading = false;

        _this3.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleReturn: function handleReturn(row) {
      var _this4 = this;

      this.dataDialog = true;
      this.dialogForm.order_id = row.ordersn;
      this.dialogForm.money = "";
      this.$nextTick(function () {
        _this4.$refs["dialogForm"].clearValidate();
      });
    },
    handleEnd: function handleEnd(row) {
      var _this5 = this;

      console.log("结束订单");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u7ED3\u675F\u8BE5".concat(row.mobile, "\u8BA2\u5355?");
      var title = "结束订单";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = {
          order_id: row.ordersn
        };
        updateEndOrder(data).then(function (res) {
          console.log(res);

          _this5.$message({
            type: "success",
            message: "结束订单成功"
          });

          _this5.getTableData();
        }).catch(function (err) {
          _this5.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleCost: function handleCost(row) {
      var _this6 = this;

      this.costListTitle = "订单:" + row.ordersn + " 费用明细";
      this.showCostList = true;
      this.tableCostListLoading = true;
      fetchOrderCostList({
        order_id: row.id
      }).then(function (res) {
        console.log(res);
        _this6.costList = res.data;
        _this6.tableCostListLoading = false;
      }).catch(function (err) {
        _this6.tableCostListLoading = false;

        _this6.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this7 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this7.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            _this7.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this8 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this8.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this8.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    //搜索充电桩
    remoteCharging: function remoteCharging(query) {
      var _this9 = this;

      if (query !== "") {
        this.chargingLoading = true;
        setTimeout(function () {
          _this9.chargingLoading = false;
          var data = {
            keyword: query
          };
          fetchEquList(data).then(function (res) {
            console.log(res);
            _this9.chargingOptions = res.data;
          });
        }, 100);
      } else {
        this.chargingOptions = [];
      }
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    handleChargeData: function handleChargeData(row) {
      this.$refs.chargeDataChart.showChart(row.id, row.ordersn);
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    handleOccupy: function handleOccupy(row) {
      this.$refs.occupyList.showList(row.id);
    },
    transPayState: transPayState,
    orderStopCode: orderStopCode,
    transChargeType: transChargeType
  }
};