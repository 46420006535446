var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "占位费列表",
        width: "1200px",
        visible: _vm.dialogVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          attrs: { data: _vm.occupyList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: "ID",
              "header-align": "center",
              align: "center",
              width: "80px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "occupy_tmpl.name",
              label: "扣费规则",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticStyle: { "padding-left": "10px" } }, [
                      _c("div", [
                        _vm._v(
                          "模板名称：" + _vm._s(scope.row.occupy_tmpl.name)
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "扣费说明：" + _vm._s(scope.row.occupy_tmpl.content)
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "开始时间：" +
                            _vm._s(scope.row.occupy_tmpl.start_time) +
                            _vm._s(
                              _vm.getTimeType(scope.row.occupy_tmpl.time_type)
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "截止时间：" +
                            _vm._s(scope.row.occupy_tmpl.end_time) +
                            _vm._s(
                              _vm.getTimeType(scope.row.occupy_tmpl.time_type)
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "单次扣费：" + _vm._s(scope.row.occupy_tmpl.amount)
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "封顶费用：" +
                            _vm._s(scope.row.occupy_tmpl.max_amount)
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "扣费间隔：" +
                            _vm._s(scope.row.occupy_tmpl.interval) +
                            " " +
                            _vm._s(
                              _vm.getTimeType(scope.row.occupy_tmpl.time_type)
                            )
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "duration",
              label: "占位时长",
              "header-align": "center",
              align: "center",
              width: "80px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.duration) +
                        " " +
                        _vm._s(_vm.getTimeType(scope.row.time_type)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: "金额",
              "header-align": "center",
              align: "center",
              width: "80px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              "header-align": "center",
              align: "center",
              width: "80px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == "wait_pay"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("待支付")
                        ])
                      : _vm._e(),
                    scope.row.status == "payed"
                      ? _c("el-tag", { attrs: { type: "" } }, [
                          _vm._v("已付款")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "remark",
              label: "备注",
              "header-align": "left",
              align: "left",
              width: "130px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "created_at",
              label: "计费时间",
              "header-align": "center",
              align: "center",
              width: "160px"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "padding-top": "10px" }
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }