//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchOrderOccupyList } from "@/api/charge";
import "@/utils/global.js";
export default {
  name: "OccupyList",
  data: function data() {
    return {
      dialogVisible: false,
      tableLoading: false,
      occupyList: []
    };
  },
  methods: {
    showList: function showList(order_id) {
      var _this = this;

      this.dialogVisible = true;
      this.tableLoading = true;
      fetchOrderOccupyList({
        order_id: order_id
      }).then(function (response) {
        _this.occupyList = response.data;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.occupyList = [];
      this.dialogVisible = false;
    },
    getTimeType: function getTimeType(type) {
      switch (type) {
        case 'minute':
          return "分钟";

        case 'hour':
          return "小时";

        case 'day':
          return "天";

        default:
          break;
      }
    }
  }
};