//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import { fetchChargingDataDetail } from "@/api/charge";
export default {
  name: "ChargeDataChart",
  data: function data() {
    return {
      title: "充电数据分析",
      dialogVisible: false,
      chart: {},
      order_id: "",
      ordersn: ""
    };
  },
  methods: {
    showChart: function showChart(order_id, ordersn) {
      var _this = this;

      this.order_id = order_id;
      this.ordersn = ordersn;
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this.drawLine();
      });
    },
    close: function close() {
      this.$echarts.dispose(this.chart);
      this.chart = null;
      this.dialogVisible = false;
    },
    drawLine: function drawLine() {
      var _this2 = this;

      // 接下来的使用就跟之前一样，初始化图表，设置配置项
      this.chart = this.$echarts.init(this.$refs.chargeChart);
      this.chart.showLoading();
      fetchChargingDataDetail(this.order_id).then(function (response) {
        _this2.chart.hideLoading();

        var data = response.data;
        var option = {
          title: {
            text: _this2.ordersn
          },
          toolbox: {
            feature: {
              dataView: {
                show: true,
                readOnly: true
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          grid: {
            left: '5%'
          },
          xAxis: {
            type: 'category',
            name: '充电时间',
            data: data.xAxis,
            axisTick: {
              alignWithLabel: true
            }
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: 'cross'
            }
          },
          legend: {
            data: ["功率", "电流", "电压"]
          },
          yAxis: [{
            type: 'value',
            name: '功率',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true
            },
            axisLabel: {
              formatter: '{value} W'
            }
          }, {
            type: 'value',
            name: '电流',
            position: 'right',
            alignTicks: true,
            offset: 80,
            axisLine: {
              show: true
            },
            axisLabel: {
              formatter: '{value} A'
            }
          }, {
            type: 'value',
            name: '电压',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true
            },
            axisLabel: {
              formatter: '{value} V'
            }
          }],
          series: [{
            name: "功率",
            type: "line",
            smooth: true,
            data: data.power,
            tooltip: {
              valueFormatter: function valueFormatter(value) {
                return value + ' W';
              }
            }
          }, {
            name: "电流",
            type: "line",
            smooth: true,
            data: data.current,
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function valueFormatter(value) {
                return value + ' A';
              }
            }
          }, {
            name: "电压",
            type: "line",
            smooth: true,
            data: data.voltage,
            yAxisIndex: 2,
            tooltip: {
              valueFormatter: function valueFormatter(value) {
                return value + ' V';
              }
            }
          }]
        };
        setTimeout(function () {
          _this2.chart && _this2.chart.setOption(option);
        }, 100);
      }).catch(function (err) {
        _this2.chart.hideLoading();

        _this2.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    }
  }
};